function init() {
    checkConsentEvery(1000);
}

var checkInterval = null;
var checkTimer = null;
var overlaysEnabled = false;

function checkConsentEvery(interval) {
    if (interval === checkInterval) {
        return;
    }
    if (checkTimer !== null) {
        clearInterval(checkTimer);
    }
    checkInterval = interval;
    checkTimer = setInterval(checkConsent, interval);
}

function checkConsent() {
    // Ensure the function get current cookie consent exists.
    if (typeof getCkyConsent === "undefined") {
        return;
    }

    const consent = getCkyConsent();

    // We should enable the overlays in case we didn't get all grants.
    const overlaysShouldBeEnabled = !(consent.categories.analytics);

    // Check if we're already in the desired state.
    if (overlaysEnabled === overlaysShouldBeEnabled) {
        return;
    }

    if (overlaysShouldBeEnabled) {
        addOverlays();
    } else {
        removeOverlays();

        // Check passed. We can relax the interval now.
        checkConsentEvery(10000);
    }

    overlaysEnabled = overlaysShouldBeEnabled;
}

function addOverlays() {
    document.querySelectorAll('.premium-content').forEach(addOverlay);
}

// addOverlay add the premium content overlay to a container.
function addOverlay(container) {
    const overlay = document.createElement("div");
    overlay.className= "premium-content-overlay";
    overlay.innerHTML = [
        `<div class="alert alert-danger" role="alert">`,
        `<h4>Non hai accettato i cookie</h4>`,
        `<p>Per visualizzare questo contenuto è necessario <strong>accettare i cookie</strong>.</p>`,
        `<hr>`,
        `<p><a class="btn btn-danger">Apri Impostazioni</a></p>`,
        `</div>`
    ].join('');

    container.appendChild(overlay);

    // Handle the button click.
    overlay.querySelectorAll('a').forEach((anchor) => {
        anchor.onclick = function(event) {
            event.preventDefault();
            revisitCkyConsent();
            return false;
        };
    });
}

function removeOverlays() {
    document.querySelectorAll('.premium-content-overlay').forEach(removeOverlay);
}

function removeOverlay(overlay) {
    overlay.remove();
}

init();
