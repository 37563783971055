import { getData, resetValidationErrors, setDisabled, setProcessing, showValidationErrors, setDisplayed } from "../util/form";

// TODO se modifico il form, il risultato del rendimento dovrebbe essere nascosto
function initNewsletterSubscribeWidget() {
    const formID = "newsletter-subscribe";
    const form = document.getElementById(formID);
    if (!form) {
        return;
    }

    form.onsubmit = async (event) => {
        event.preventDefault();

        // Get the form data before disabling it.
        const params = new URLSearchParams(getData(form));

        // Update UI.
        resetValidationErrors(form);
        setDisabled(form, true);
        setProcessing(form, true);

        // Call the API
        const res = (await fetch('/newsletter/subscribe?' + params.toString(), {
            method: 'POST',
        }));

        const resBody = await res.json();

        // Update UI (keep it disabled on success).
        setProcessing(form, false);

        if (res.status >= 400 && res.status < 500) {
            setDisabled(form, false);
            showValidationErrors(form, resBody);
            return
        }

        setDisplayed(document.getElementById("newsletter-subscribe-success"), true);
    };
}

initNewsletterSubscribeWidget();
