// Import and expose chart.js.
import {
    Chart,
    BarElement,
    BarController,
    LineElement,
    LineController,
    PointElement,
    CategoryScale,
    LinearScale,
    Legend,
    Tooltip
} from 'chart.js';

Chart.register(
    BarElement,
    BarController,
    LineElement,
    LineController,
    PointElement,
    CategoryScale,
    LinearScale,
    Legend,
    Tooltip
);

window.Chart = Chart;

// Import and expose boostrap.
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";

import "./widget/yield-calculator";
import "./widget/header";
import "./widget/newsletter-subscribe";
import "./widget/premium";
import "./widget/survey";
import "./widget/table";
import "./widget/tooltip";
