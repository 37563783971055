import { getData, resetValidationErrors, setDisabled, setProcessing, showValidationErrors, setDisplayed } from "../util/form";

// TODO se modifico il form, il risultato del rendimento dovrebbe essere nascosto
function init() {
    const formID = "yield-calculator";
    const form = document.getElementById(formID);
    if (!form) {
        return;
    }

    form.onsubmit = async (event) => {
        event.preventDefault();

        // Get the form data before disabling it.
        const params = new URLSearchParams(getData(form));

        // Update UI.
        resetValidationErrors(form);
        setDisabled(form, true);
        setProcessing(form, true);
        setDisplayed(document.getElementById("yield-calculator-result"), false);

        // Call the API
        const res = (await fetch('/api/bonds/yield-calculator?' + params.toString(), {
            method: 'POST',
        }));

        const resBody = await res.json();

        // Update UI.
        setDisabled(form, false);
        setProcessing(form, false);

        if (res.status >= 400 && res.status < 500) {
            showValidationErrors(form, resBody);
            return
        }

        showResult(document.getElementById("yield-calculator-result"), resBody)
    };
}

function showResult(container, res) {
    const containerID = container.id;

    // Fill the result.
    for (let [key, value] of Object.entries(res.data)) {
        const el = document.getElementById(containerID + "-" + key.replaceAll("_", "-"));
        if (el) {
            // Round float values.
            if (typeof value == "number") {
                value = value.toFixed(2);
            }

            el.innerText = `${value}`;
        }
    }

    // Show it.
    setDisplayed(container, true);
    container.scrollIntoView();
}

init();
