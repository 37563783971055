export function setDisabled(form, disabled) {
    ["input", "select", "button"].forEach((type) => {
        for (let el of form.getElementsByTagName(type)) {
            el.disabled = disabled;
        }
    });
};

export function setProcessing(form, processing) {
    const spinner = form.querySelector("button[type=submit] span.spinner-border");
    if (!spinner) {
        return;
    }

    setDisplayed(spinner, processing);
};

export function getData(form) {
    let data = {};

    for (const [key, value] of new FormData(form)) {
        data[key] = value;
    }

    return data;
};

export function showValidationErrors(form, res) {
    // Show validation errors.
    let firstInvalidField;

    for (const [name, errors] of Object.entries(res.validation)) {
        const el = form.querySelector(`[name=${name}]`);
        if (!el) {
            // TODO add to global errors
            return;
        }

        el.classList.add("is-invalid");

        for (const errMessage of errors) {
            el.parentNode.insertBefore(createInvalidFeedbackElement(errMessage), el.nextSibling);
        }

        if (!firstInvalidField) {
            // TODO el is not first because ordering is not guaranteed.
            firstInvalidField = el;
        }
    }

    // Global errors.
    if (res.global) {
        const submit = form.querySelector("button[type=submit]");
        if (submit) {
            submit.classList.add("is-invalid");

            for (const msg of res.global) {
                submit.parentNode.insertBefore(createInvalidFeedbackElement(msg), submit.nextSibling);
            }
        }
    }

    // Scroll to the first invalid field.
    if (firstInvalidField) {
        firstInvalidField.scrollIntoView();
    }
};

export function resetValidationErrors(form) {
    ["is-valid", "is-invalid"].forEach(function (cls) {
        form.querySelectorAll(`.${cls}`).forEach((el) => {
            el.classList.remove(cls);
        });
    })

    form.querySelectorAll(".invalid-feedback").forEach((el) => {
        el.remove();
    });
};

export function setDisplayed(el, displayed) {
    if (!el) {
        return;
    }

    if (displayed) {
        el.classList.remove("d-none");
    } else {
        el.classList.add("d-none");
    }
};

export function createInvalidFeedbackElement(msg) {
    const el = document.createElement("div");
    el.classList.add("invalid-feedback");
    el.innerText = msg;

    return el;
};
