import { setDisplayed } from "../util/form";

/**
 * An expandable table is a table where some rows are hidden by default, and they
 * can be displayed clicking a button. An expandable table requires:
 * - The "table-expandable" class on the <table>
 * - A <button class="table-expandable-action"> in the table <tfoot>
 */
function registerExpandableTables() {
    // Find all expandable tables.
    for (let table of document.querySelectorAll('table.table-expandable')) {
        // Expand the table on click on the button in the table footer.
        let button = table.querySelector('tfoot button.table-expandable-action');
        if (!button) {
            continue
        }

        button.onclick = async() => {
            expandTable(table);

            // Scroll to the top of the table.
            table.querySelector('thead').scrollIntoView();
        };
    }
}

function registerClickableTables() {
    // Find all table rows with data-link attribute.
    for (let row of document.querySelectorAll('tr[data-link]')) {
        let link = row.getAttribute('data-link');
        if (!link) {
            continue
        }

        row.onclick = async () => {
            document.location = link;
        }
    }
}

function registerSortableTable(table) {
    // Find all sortable columns.
    for (let [idx, col] of Object.entries(table.querySelectorAll('thead th'))) {
        // Skip if the column is not sortable.
        if (!col.classList.contains('table-column-sortable')) {
            continue
        }

        // Ensure the index is numeric.
        idx = parseInt(idx);

        col.onclick = async() => {
            let ascending;

            // If the table is expandable, we need to make sure it was expanded.
            expandTable(table);

            // Detect the desired sorting.
            if (isColumnSortingActive(col)) {
                ascending = !isColumnSortingAscending(col);
            } else {
                ascending = isColumnSortingAscending(col);
            }

            // Disable any other active sorted column.
            for (let icon of table.querySelectorAll('thead th.table-column-sortable i.active')) {
                icon.classList.remove('active');
                icon.classList.add('inactive');
            }

            // Enable the sorting on the selected column.
            let icon = col.querySelector('i');
            if (icon) {
                icon.classList.remove('inactive');
                icon.classList.add('active');

                if (ascending) {
                    icon.classList.remove('bi-sort-up-alt');
                    icon.classList.add('bi-sort-down-alt');
                } else {
                    icon.classList.remove('bi-sort-down-alt');
                    icon.classList.add('bi-sort-up-alt');
                }
            }

            // Sort the table by the selected column.
            sortTable(table, idx, ascending);
        };
    }
}

function registerSortableTables() {
    // Find all sortable tables.
    for (let table of document.querySelectorAll('table.table-sortable')) {
        registerSortableTable(table);
    }
}

function expandTable(table) {
    // Skip if the table is not expandable.
    if (!table.classList.contains("table-expandable")) {
        return
    }

    // Show all rows.
    for (let row of table.querySelectorAll('tbody tr')) {
        setDisplayed(row, true);
    }

    // Hide footer.
    setDisplayed(table.querySelector('tfoot'), false);

    // Mark the table as not expandable.
    table.classList.remove("table-expandable");
}

function sortTable(table, colIndex, ascending) {
    // Get all rows.
    let rows = Array.from(table.querySelectorAll('tbody tr'));

    // Sort rows.
    let colChildIndex = colIndex + 1;
    let colSelector = `td:nth-child(${colChildIndex})`;

    rows.sort(function (a, b) {
       let aCol = a.querySelector(colSelector);
       let bCol = b.querySelector(colSelector);

       let aValue = getColumnSortableValue(aCol);
       let bValue = getColumnSortableValue(bCol);

       if (ascending) {
           return aValue.localeCompare(bValue);
       } else {
           return bValue.localeCompare(aValue);
       }
    });

    // Apply the sorting to the table.
    for (let row of rows) {
        let parent = row.parentNode;

        parent.removeChild(row);
        parent.appendChild(row);
    }
}

function isColumnSortingAscending(col) {
    let icon = col.querySelector('i');
    return icon && icon.classList.contains('bi-sort-down-alt');
}

function isColumnSortingActive(col) {
    let icon = col.querySelector('i');
    return icon && icon.classList.contains('active');
}

function getColumnSortableValue(col) {
    if (!col) {
        return "";
    }

    let sortBy = col.getAttribute('data-sort-by');
    if (sortBy) {
        return sortBy;
    }

    return col.innerText.trim();
}

function init() {
    registerClickableTables();
    registerExpandableTables();
    registerSortableTables();
}

init();
