import {
    getData,
    resetValidationErrors,
    setDisabled,
    setDisplayed,
    setProcessing,
    showValidationErrors
} from "../util/form";

function init() {
    document.querySelectorAll('.survey').forEach(registerSurvey);
}

function registerSurvey(container) {
    let hasLocalStorage = typeof localStorage === 'object';
    let activeEl = container.querySelector('.survey-active');
    let inactiveEl = container.querySelector('.survey-inactive');

    // Ensure it hasn't already voted.
    if (hasLocalStorage && localStorage.getItem('survey_completed') === 'true') {
        setDisplayed(activeEl, false);
        setDisplayed(inactiveEl, true);

        return;
    }

    // Get the elements.
    let formEl = container.querySelector('form.survey-form');
    let submittedEl = container.querySelector('.survey-submitted');
    if (!formEl || !submittedEl) {
        return;
    }

    // Enable submit button when any preference is selected.
    (formEl.preference || []).forEach((radioEl) => {
        radioEl.addEventListener('change', () => {
            const submitEl = formEl.querySelector('button[type=submit]');
            if (submitEl) {
                submitEl.classList.remove('disabled');
            }
        });
    });

    formEl.onsubmit = async (event) => {
        event.preventDefault();

        // Get the form data before disabling it.
        let data = getData(formEl);

        // Hide the form and display the done message.
        setDisplayed(formEl, false);
        setDisplayed(submittedEl, true);

        // Track it.
        if (typeof gtag === 'function') {
            gtag('event', 'survey_result', data);
        } else {
            console.log('Google Analytics not available - Survey data:', data);
        }

        // Save the survey has been completed.
        if (hasLocalStorage) {
            localStorage.setItem('survey_completed', 'true');
        }
    };
}

init();
